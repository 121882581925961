//var printer = require("../../../node_modules/thermal-printer");


function printDocument() {
    alert("wuuu")
    // printer.init({
    //     type: 'epson',
    //     interface: '/dev/usb/lp0'
    // });
    // printer.alignCenter();
    // printer.println("Hello world", function (done) {
    //     printer.cut();
    //     printer.execute(function (err) {
    //         if (err) {
    //             console.error("Print failed", err);
    //         } else {
    //             console.log("Print done");
    //         }
    //     });
    // });



    // printer.printImage('./assets/olaii-logo-black.png', function (done) {
    //     printer.cut();
    //     printer.execute(function (err) {
    //         if (err) {
    //             console.error("Print failed", err);
    //         } else {
    //             console.log("Print done");
    //         }
    //     });
    // });

}
